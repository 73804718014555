import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { app } from "../utils/server/firebase";
import React, { useState } from "react";
import Footer from "../assets/components/Footer";
import Navbar from "../assets/components/Navbar";
import Seo from "../assets/components/seo";
import { sendEmail, validateEmail } from "../utils/functions";
import { toast } from "react-toastify";
import { ESTADOS } from "../utils/constants";

const db = getFirestore(app);

const ChocolateMesaAgua = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const ocupaciones = [
    "Chocolatero",
    "Empleado de chocolatería",
    "Cacaotero",
    "Chef",
    "Repostero",
    "Ama de casa",
    "Amante del chocolate",
  ];

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (
        !data?.nombre?.trim() ||
        !data?.telefono?.trim() ||
        !data?.email?.trim() ||
        !data?.ocupacion?.trim() ||
        !data?.direccion?.trim() ||
        !data?.estado?.trim() ||
        !data?.ciudad?.trim() ||
        !data?.nivel?.trim() ||
        !data?.porque?.trim()
      ) {
        return toast.warning("Favor de llenar todos los campos");
      } else if (!validateEmail(data?.email?.trim())) {
        return toast.info("Revise que el correo electrónico sea valido");
      }
      try {
        const q = query(
          collection(db, "certificacion"),
          where("email", "==", data.email)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length > 0)
          return toast.error(
            "El email que ingresó ya se encuentra registrado para este concurso"
          );
      } catch (error) {
        return toast.warning("Ocurrio un error: " + error.message);
      }

      const docsRef = collection(db, "certificacion");
      await addDoc(docsRef, {
        nombre: data?.nombre,
        telefono: data?.telefono,
        email: data?.email,
        empresa: data?.empresa || "",
        direccion: data?.direccion,
        estado: data?.estado,
        ciudad: data?.ciudad,
        ocupacion: data?.ocupacion,
        porque: data?.porque,
        nivel: data?.nivel,
        createAt: new Date(),
      });

      toast.success("Registro finalizado");
    } catch (error) {
      console.log(error);
      toast.error(
        "Hubo un problema al crear tu solicitud. Recargue e intente de nuevo."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo />
      <Navbar />

      <Grid container justifyContent="center" marginY={12}>
        <Grid container maxWidth="sm" spacing={2} paddingX={4}>
          <Grid item container alignItems="center">
            <Grid item xs={4}>
              <img
                src="https://i.ibb.co/fkN9Znb/192512985-166053408870714-7665941542673861805-n.jpg"
                width="100%"
                height="100%"
                style={{ maxWidth: 150, objectFit: "cover" }}
                alt="Logo Chocolate Awards"
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h5" textTransform="uppercase">
                Certificación nivel 1 y 2 del International Institute of Cacaco
                and Chocolate Tasting
              </Typography>
            </Grid>
            {/*  <Grid item>
              <Typography>
                Concurso Gastronómico de impacto INTERNACIONAL, que forma parte
                del programa “Sabor a Tabasco”, y que tiene como objetivo el
                intercambio de sabores y saberes gastronómicos vinculados a la
                cocina tradicional, así como la puesta en valor y difusión de la
                identidad culinaria de las diferentes regiones de Mesoamérica.
              </Typography>
            </Grid> */}
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Nombre del participante"
              fullWidth
              required
              value={data?.nombre || ""}
              onChange={(e) => {
                setData({ ...data, nombre: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              style={{ textAlign: "left" }}
              select
              fullWidth
              label="Ocupación *"
              color="primary"
              name="ocupacion"
              value={data?.ocupacion || ""}
              onChange={(e) => {
                setData({ ...data, ocupacion: e.target.value });
              }}
            >
              <MenuItem value="" disabled selected>
                Seleccione una ocupación
              </MenuItem>
              {ocupaciones.map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Nombre de su empresa"
              fullWidth
              value={data?.empresa || ""}
              onChange={(e) => {
                setData({ ...data, empresa: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Dirección"
              fullWidth
              value={data?.direccion || ""}
              onChange={(e) => {
                setData({ ...data, direccion: e.target.value });
              }}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              style={{ textAlign: "left" }}
              select
              fullWidth
              label="Estado *"
              color="primary"
              name="estado"
              value={data?.estado || ""}
              onChange={(e) => {
                setData({ ...data, estado: e.target.value });
              }}
            >
              <MenuItem value="" disabled selected>
                Seleccione un estado
              </MenuItem>
              {ESTADOS.map((estado, key) => (
                <MenuItem key={key} value={estado}>
                  {estado}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Ciudad"
              fullWidth
              required
              value={data?.ciudad || ""}
              onChange={(e) => {
                setData({ ...data, ciudad: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Teléfono"
              fullWidth
              required
              value={data?.telefono || ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value == "" || regex.test(e.target.value)) {
                  setData({ ...data, telefono: e.target.value });
                }
              }}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Correo electrónico"
              fullWidth
              required
              value={data?.email || ""}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              style={{ textAlign: "left" }}
              select
              fullWidth
              label="Nivel a cursar *"
              color="primary"
              name="nivel"
              value={data?.nivel || ""}
              onChange={(e) => {
                setData({ ...data, nivel: e.target.value });
              }}
            >
              <MenuItem value="" disabled selected>
                Seleccione un nivel
              </MenuItem>
              {["1", "2", "Combinado"].map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="¿Por qué se quiere certificar?"
              fullWidth
              required
              value={data?.porque || ""}
              onChange={(e) => {
                setData({ ...data, porque: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              disabled={loading ? true : false}
            >
              {loading ? "Guardando registro" : "Finalizar registro"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default ChocolateMesaAgua;
